/**
 * Stores build information. The string literals are replaced at compile time by `set_version.ts`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2024-09-04",
    versionChannel: "nightly",
    buildDate: "2024-09-04T00:05:40.294Z",
    commitHash: "48afad257f70f905a01c73868235850ddee4df20",
};
